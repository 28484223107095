export const PARTNER_NAMES = {
  ORPHEUS: 'orpheus',
  ACHILLES: 'achilles',
  LOCKTON: 'lockton',
  SOFTCAT: 'softcat',
  DUAL: 'dual',
  ARMOUR_CYBERSECURITY: 'armourcybersecurity'
}

export const PARTNER_DISPLAY_NAMES = {
  orpheus: 'Orpheus',
  achilles: 'Achilles',
  lockton: 'Lockton',
  softcat: 'Softcat',
  dual: 'Dual',
  armourcybersecurity: 'Armour Cybersecurity',
}