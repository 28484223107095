import React from 'react'

export default () => (
  <>
    <h1 style={{ marginTop: '20px', paddingBottom: '0.2em', fontSize: '1.6vw' }}>
      Achilles has partnered with Orpheus to help you immediately improve your cyber security.
    </h1>

    <p style={{ fontSize: "18px" }}>
      <b>Ongoing monitoring is an important part in an effective cyber risk management process, which is why Achilles is now providing cyber risk ratings on suppliers and sharing them to buyers on the Achilles platform.</b>
    </p>
  </>
)