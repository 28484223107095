import React from 'react'
import { PARTNER_NAMES } from '../../../../constants'
import AchillesCopy from './achilles'
import LocktonCopy from './lockton'
import SoftcatCopy from './softcat'
import OrpheusCopy from './orpheus'
import DualCopy from './dual'
import ArmourCybersecurityCopy from './armour-cybersecurity'


export default ({ partnerName }) => {

  if (partnerName === PARTNER_NAMES.ORPHEUS) return <OrpheusCopy />
  if (partnerName === PARTNER_NAMES.ACHILLES) return <AchillesCopy />
  if (partnerName === PARTNER_NAMES.LOCKTON) return <LocktonCopy />
  if (partnerName === PARTNER_NAMES.SOFTCAT) return <SoftcatCopy />
  if (partnerName === PARTNER_NAMES.DUAL) return <DualCopy />
  if (partnerName === PARTNER_NAMES.ARMOUR_CYBERSECURITY) return <ArmourCybersecurityCopy />

  return <></>
}