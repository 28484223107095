import React from "react";
import Wrap from "../../../global-components/layout/wrap";
import styles from "./hero.module.css";
import CustomerForm from "../../../global-components/checkout/customer-form";
import OrpheusLogo from '../../../images/orpheus-logo.svg'
import OrpheusLogoPNG from '../../../images/orpheus-logo.png'
import PartnerLogo from './partner-logo'
import PartnerCopy from './partner-copy'
import { PARTNER_NAMES } from "../../../constants";

const Hero = ({ partnerName }) => {

  return (
    <section className={styles.hero} style={{ paddingTop: '2vw' }}>
      <Wrap>

        <div>
          <PartnerLogo partnerName={partnerName} />
          {partnerName === PARTNER_NAMES.ORPHEUS && <img alt="Orpheus" src={OrpheusLogoPNG} style={{ height: '120px' }} />}
          {partnerName !== PARTNER_NAMES.ORPHEUS && <img alt="Orpheus" src={OrpheusLogo} style={{ height: '50px' }} />}
        </div>

        <PartnerCopy partnerName={partnerName} />

        <CustomerForm partnerName={partnerName}></CustomerForm>
      </Wrap>
    </section>
  )
}

export default Hero;
