import React from "react";

import Layout from "../../global-components/layout/layout";
import Hero from "./components/hero";
import styles from "./index.module.css";

// import LinkedInTag from "react-linkedin-insight";

// import { getConfig } from "../../utils";

const CRRReportPage = ({ partnerName }) => {
  return (
    <>
      <div className={styles.landingPageContent}>
        <Layout>
          <Hero partnerName={partnerName} />
        </Layout>
      </div>
    </>
  );
}

export default CRRReportPage
