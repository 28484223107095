import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack'
import React, { useEffect, useState } from "react"
import CheckMarkGold from '../../images/check.svg'
import Button from "../button/button"
import Loader from '../loader/loader'
import formStyles from "./form-elements.module.css"
import { PARTNER_NAMES } from '../../constants'
import { usePostHog } from 'posthog-js/react'

const getPartnerOrgSettingsByName = partnerName => {
  const orgIdMap = {
    achilles: {
      partnershipPrettyName: 'Achilles',
      partnershipName: 'achilles',
      partnerOrgId: 224
    },
    lockton: {
      partnershipPrettyName: 'Lockton',
      partnershipName: 'lockton',
      partnerOrgId: 1188
    },
    orpheus: {
      partnershipPrettyName: 'Orpheus',
      partnershipName: 'orpheus',
      partnerOrgId: 1195
    },
    softcat: {
      partnershipPrettyName: 'Softcat',
      partnershipName: 'softcat',
      partnerOrgId: 1189
    },
    dual: {
      partnershipPrettyName: 'Dual',
      partnershipName: 'dual',
      partnerOrgId: 462
    },
    armourcybersecurity: {
      partnershipPrettyName: 'Armour Cybersecurity',
      partnershipName: 'armourCybersecurity',
      partnerOrgId: 1254
    },
  }

  return orgIdMap[partnerName] || {}
}

export default function CustomerForm({ partnerName }) {
  const posthog = usePostHog()
  const [customerName, setCustomerName] = useState(process.env.REACT_APP_AUTOCOMPLETE_YOUR_NAME || '')
  const [customerEmail, setCustomerEmail] = useState(process.env.REACT_APP_AUTOCOMPLETE_YOUR_EMAIL || '')
  const [companyName, setCompanyName] = useState(process.env.REACT_APP_AUTOCOMPLETE_COMPANY_NAME || '')
  const [companyDomain, setCompanyDomain] = useState(process.env.REACT_APP_AUTOCOMPLETE_COMPANY_DOMAIN || '')
  const [contactName, setContactName] = useState(process.env.REACT_APP_AUTOCOMPLETE_CONTACT_NAME || '')
  const [contactEmail, setContactEmail] = useState(process.env.REACT_APP_AUTOCOMPLETE_CONTACT_EMAIL || '')
  const [contactPhone, setContactPhone] = useState(process.env.REACT_APP_AUTOCOMPLETE_CONTACT_PHONE || '')
  const [invalidFields, setInvalidFields] = useState([])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const handleSubmit = async () => {
    if (submitDisabled) return
    closeSnackbar()
    setIsSubmitting(true)
    const formBody = {
      customerDetails: {
        customerName,
        customerEmail,
        companyName,
        companyDomain,
        contactName,
        contactEmail,
        contactPhone,
      },
      settings: getPartnerOrgSettingsByName(partnerName)
    }
    try {
      const reportRequestResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/request-report`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formBody)
      })

      if (reportRequestResponse.status !== 200) {
        const json = await reportRequestResponse.json()
        if (json.invalidFields) {
          const fields = Object.keys(json.invalidFields)
          const messages = Object.values(json.invalidFields)
          for (const error of messages) {
            enqueueSnackbar(error, { anchorOrigin: { horizontal: 'right', vertical: 'top' }, autoHideDuration: 5000 * fields.length, style: { background: '#d6614d' } })
          }
          setInvalidFields(fields)
          posthog.capture('[CUSTOM] customer form submission failed with validation errors', { formBody, errors: json.invalidFields })
        } else {
          throw new Error("An error occurred. Please try again later.")
        }
      } else {
        posthog.capture('[CUSTOM] customer form submitted successfully', { formBody })
        setFormSubmitted(true)
        setIsSubmitting(false)
      }

    } catch (e) {
      posthog.capture('[CUSTOM] customer form submission failed with an unknown error', { formBody, error: e.message })
      enqueueSnackbar('An error occurred. Please try again later', { anchorOrigin: { horizontal: 'right', vertical: 'top' }, style: { background: '#d6614d' } })
    }

    setIsSubmitting(false)
  }

  useEffect(() => {
    setSubmitDisabled(
      customerName.length === 0
      || customerEmail.length === 0
      || companyName.length === 0
      || companyDomain.length === 0
      || contactName.length === 0
      || contactEmail.length === 0
      || isSubmitting
    )
  }, [customerName.length, customerEmail.length, companyName.length, companyDomain.length, contactName.length, contactEmail.length, isSubmitting, invalidFields])

  const getErrorStyle = fieldName => {
    return invalidFields.includes(fieldName)
      ? { border: '2px #d6614d solid', color: 'white' }
      : { background: 'white', color: '' }
  }

  return (
    <form onSubmit={e => e.preventDefault()} autoComplete="off">
      <SnackbarProvider disableWindowBlurListener={true} maxSnack={10} />
      {!formSubmitted && <>
        {partnerName !== PARTNER_NAMES.ORPHEUS && <p style={{ marginTop: '30px', fontSize: 18 }}>Request your free Orpheus account using the form below</p>}
        <div className={formStyles.formGroup} style={{ marginTop: '20px', ...getErrorStyle('customerName') }}>
          <div className={formStyles.element}>
            <label className={formStyles.label}>Your Name</label>
            <input
              className={formStyles.input}
              placeholder="Jane Doe"
              minLength={2}
              required
              autoFocus
              defaultValue={customerName}
              onChange={e => {
                setCustomerName(e.target.value)
              }}
              disabled={isSubmitting}
            />
          </div>
          <div className={formStyles.element} style={{ ...getErrorStyle('customerEmail') }}>
            <label className={formStyles.label}>Your Email</label>
            <input
              className={formStyles.input}
              type="email"
              placeholder="janedoe@company.com"
              required
              defaultValue={customerEmail}
              onChange={e => {
                setCustomerEmail(e.target.value)
                // if (!emailValidator.validate(e.target.value)) {
                //   e.target.setCustomValidity('Please enter a valid email address')
                //   e.target.reportValidity()
                //   return
                // }
              }}
              disabled={isSubmitting}
            />
          </div>
        </div>

        <div className={formStyles.formGroup} style={{ marginTop: 20, ...getErrorStyle('companyName') }}>
          <div className={formStyles.element}>
            <label className={formStyles.label}>Company Name</label>
            <input
              className={formStyles.input}
              placeholder="My Company"
              required
              minLength={2}
              defaultValue={companyName}
              onChange={e => {
                setCompanyName(e.target.value)
                if (e.target.value.length === 0) {
                  e.target.setCustomValidity('Please enter a company name')
                  e.target.reportValidity()
                  return
                }
              }}
              disabled={isSubmitting}
            />
          </div>
          <div className={formStyles.element} style={{ ...getErrorStyle('companyDomain') }}>
            <label className={formStyles.label}>Company Domain</label>
            <input
              className={formStyles.input}
              placeholder="company.com"
              required
              defaultValue={companyDomain}
              onChange={e => {
                setCompanyDomain(e.target.value)
              }
              }
              disabled={isSubmitting}
            />
          </div>
        </div>

        <div className={formStyles.formGroup} style={{ marginTop: 20, ...getErrorStyle('contactName') }}>
          <div className={formStyles.element}>
            <p style={{ fontSize: '14px', textAlign: 'left', fontWeight: 'bold' }}>Please enter the details of the person who is responsible for cyber risk at your organisation</p>
          </div>
          <div className={formStyles.element}>
            <label className={formStyles.label}>Contact Name</label>
            <input
              className={formStyles.input}
              placeholder="John Doe"
              minLength={2}
              defaultValue={contactName}
              required
              onChange={e => setContactName(e.target.value)}
              disabled={isSubmitting}
            />
          </div>
          <div className={formStyles.element} style={{ ...getErrorStyle('contactEmail') }}>
            <label className={formStyles.label}>Contact Email</label>
            <input
              className={formStyles.input}
              type="email"
              placeholder="johndoe@company.com"
              defaultValue={contactEmail}
              required
              onChange={e => setContactEmail(e.target.value)}
              disabled={isSubmitting}
            />
          </div>
          <div className={formStyles.element} style={{ ...getErrorStyle('contactPhone') }}>
            <label className={formStyles.label}>Contact Phone</label>
            <input
              className={formStyles.input}
              placeholder="+447891234556"
              defaultValue={contactPhone}
              onChange={e => setContactPhone(e.target.value)}
              disabled={isSubmitting}
              type="tel"
            />
          </div>
        </div>

        <Button onClick={handleSubmit} color="gold" fullWidth style={{ textAlign: 'center', opacity: submitDisabled ? '0.5' : '1', cursor: submitDisabled ? 'default' : 'pointer' }}>
          {isSubmitting ? <Loader /> : 'Get my free account'}
        </Button>
      </>}


      {formSubmitted &&
        <div className={formStyles.formGroup} style={{ background: 'white', marginTop: 20, padding: 30, textAlign: 'center' }}>
          <img alt="" src={CheckMarkGold} style={{ width: 24, border: '1px #a0804d solid', borderRadius: '50%', padding: 10 }} />
          <p style={{ opacity: '0.8', fontSize: '18px' }}>Thanks, you will receive an invite email soon with instructions of how to setup your account. <br /><br />Please contact us on <a href="mailto:support@orpheus-cyber.com">support@orpheus-cyber.com</a> if you need any assistance.</p>
        </div>
      }
    </form>
  )
}
