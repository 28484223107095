import React from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import { Elements } from "react-stripe-elements"
import Error404 from "./global-components/errors/error-404"
import CrrReportPage from "./modules/crr-report"
import { PARTNER_DISPLAY_NAMES } from "./constants"

import { PostHogProvider } from 'posthog-js/react'

const App = (props) => {
  return (
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={{ api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST }}
    >
      <div>
        <BrowserRouter>
          <Elements cssSrc="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap">
            <Switch>
              <Route
                path="/partner/:partnerName"
                render={props => {
                  // This always returns a lowercase name
                  const partnerName = props.match.params.partnerName
                  if (!PARTNER_DISPLAY_NAMES[partnerName])
                    return <Route component={Error404} />
                  return <CrrReportPage partnerName={partnerName} />
                }}
              />
              <Route
                exact path="/"
                render={props => {
                  // This always returns a lowercase name
                  const partnerName = 'orpheus'
                  if (!PARTNER_DISPLAY_NAMES[partnerName])
                    return <Route component={Error404} />
                  return <CrrReportPage partnerName={partnerName} />
                }}
              />
              <Route exact path="/crr-report/:partnerName"
                render={props => {
                  const partnerName = props.match.params.partnerName
                  return <Redirect to={`/partner/${partnerName}`} />
                }}>
              </Route>
              <Route component={Error404} />
            </Switch>
          </Elements>
        </BrowserRouter>
      </div>
    </PostHogProvider>
  )
}

export default App
