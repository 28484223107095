import React from 'react'
import AchillesLogo from './logos/achilles.png'
import LocktonLogo from './logos/lockton.png'
import SoftcatLogo from './logos/softcat.svg'
import DualLogo from './logos/dual.png'
import ArmourCybersecurityLogo from './logos/armourcyber.jpg'
import { PARTNER_NAMES } from '../../../../constants'

const PartnerLogo = ({ partnerName = '' }) => {
  if (partnerName === PARTNER_NAMES.ACHILLES) {
    return (
      <>
        <img alt="Achilles" src={AchillesLogo} style={{ height: '50px' }} />
        <span style={{ height: '50px', display: 'inline-block', verticalAlign: 'top', padding: '10px', fontSize: '30px' }}> + </span>
      </>
    )
  }

  if (partnerName === PARTNER_NAMES.LOCKTON) {
    return (
      <>
        <img alt="Lockton" src={LocktonLogo} style={{ width: '126px' }} />
        <span style={{ height: '50px', display: 'inline-block', verticalAlign: 'top', padding: '10px', fontSize: '30px' }}> + </span>
      </>
    )
  }

  if (partnerName === PARTNER_NAMES.SOFTCAT) {
    return (
      <>
        <img alt="Softcat" src={SoftcatLogo} style={{ height: '50px' }} />
        <span style={{ height: '50px', display: 'inline-block', verticalAlign: 'top', padding: '10px', fontSize: '30px' }}> + </span>
      </>
    )
  }

  if (partnerName === PARTNER_NAMES.DUAL) return (
    <>
      <img alt="Dual" src={DualLogo} style={{ height: '50px' }} />
      <span style={{ height: '50px', display: 'inline-block', verticalAlign: 'top', padding: '10px', paddingLeft: '15px', paddingRight: '15px', fontSize: '30px' }}> + </span>
    </>
  )

  if (partnerName === PARTNER_NAMES.ARMOUR_CYBERSECURITY) return (
    <>
      <img alt="Dual" src={ArmourCybersecurityLogo} style={{ height: '50px' }} />
      <span style={{ height: '50px', display: 'inline-block', verticalAlign: 'top', padding: '10px', paddingLeft: '15px', paddingRight: '15px', fontSize: '30px' }}> + </span>
    </>
  )

  return <></>
}

export default PartnerLogo